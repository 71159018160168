import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './App.css';

type TalkingPointProps = {
  icon: FontAwesomeIconProps["icon"];
  title: String;
  description: String;
};

function TalkingPoint({ description, icon, title }: TalkingPointProps) {
  return (
    <div className="talking-point">
      <div className="talking-point-inner">
        <div>
          <FontAwesomeIcon className="primary-color" icon={icon} />
          <span className="talking-point-title">&nbsp; {title}</span>
        </div>
        <p className="talking-point-description">{description}</p>
      </div>
    </div>
  );
}

export default TalkingPoint;
