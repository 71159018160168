import React, { useState } from 'react';
import homePage from './assets/home_page_framed.png';
import questInfoPage from './assets/quest_info_page_framed.png';
import topSkills from './assets/top_skills_framed.png';
import levelUpsPage from './assets/level_ups_page_framed.png';
import progressPage from './assets/progress_page_framed.png';
import achievementsPage from './assets/achievements_page_framed.png';
import timelinePage from './assets/timeline_page_framed.png';
import './App.css';

function RightContainer() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      img: homePage,
      title: "Progress Your Character",
      description: "Level up in life, through quests that describe everything you do",
    },
    {
      img: questInfoPage,
      title: "Fully Customizable Quests",
      description: "You can customize quests however you want, with objectives and custom outcomes",
    },
    {
      img: topSkills,
      title: "Fully Customizable Skills",
      description: "You can customize your character however you want. With fully customizable attributes and skills"
    },
    {
      img: progressPage,
      title: "Track Your Progress",
      description: "Track your progress over time, and be proud of how far you've come"
    },
    {
      img: timelinePage,
      title: "See your skills progressions",
      description: "See a timeline of the entire progress you've made while learning a new skill"
    },
    {
      img: achievementsPage,
      title: "Fully Customizable Achievements",
      description: "You can customize and create your own achievements with your own conditions"
    },
    {
      img: levelUpsPage,
      title: "Track Your Level Up History",
      description: "See how well you did on each level of your journey to success"
    },
  ];
  const numberOfSlides = slides.length;

  return (
    <div className="Right-Container">
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className={`${currentSlide === 0 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1" className={`${currentSlide === 1 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2" className={`${currentSlide === 2 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3" className={`${currentSlide === 3 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4" className={`${currentSlide === 4 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5" className={`${currentSlide === 5 ? "active" : ""}`}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="6" className={`${currentSlide === 6 ? "active" : ""}`}></li>
        </ol>
        <div className="carousel-inner">
          {
            slides.map((slide, index) => {
              return (
                <div key={slide.img} className={`carousel-item ${currentSlide === index ? "active" : ""}`}>
                  <img className="iPhoneImg" src={slide.img} alt="First slide" />
                  <div className="d-block">
                    <h5 className="Slide-Title">{slide.title}</h5>
                    <p className="Slide-Description">{slide.description}</p>
                  </div>
                </div>
              );
            })
          }
        </div>
        <button className="carousel-control-prev" onClick={() => setCurrentSlide(currentSlide - 1 <= 0 ? 0 : currentSlide - 1)}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only"></span>
        </button>
        <button className="carousel-control-next" onClick={() => setCurrentSlide(currentSlide + 1 >= numberOfSlides ? currentSlide : currentSlide + 1)}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only"></span>
        </button>
      </div>
    </div>
  );
}

export default RightContainer;
