import { faApple, faGooglePlay, faInstagram, faRedditAlien, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGuitar, faQuestion, faSmile, faStreetView } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.css';
import RightContainer from './RightContainer';
import TalkingPoint from './TalkingPoint';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  const [isShowingPolicy, setIsShowingPolicy] = useState(false);

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/privacy-policy">
            <div className="App">
              <div className="App-header First-Page">
              <>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10%', paddingRight: '10%', paddingTop: '50px' }}>
                      <h1 style={{ fontWeight: 'bold', paddingBottom: '16px' }}>Privacy Policy for Dominate Life</h1>
                      <p style={{ textAlign: 'left', fontSize: '18px' }}>José Oliveira ("I", "me", or "my") operates the Dominate Life mobile application (the "Service").</p>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>This page informs you of my policies regarding the collection, use, and disclosure of personal data when you use the Service and the choices you have associated with that data.</p>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>By using the Service, you agree to the collection and use of information in accordance with this policy.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>Information Collection and Use</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>For a better experience while using the Service, please note that I do not require you to provide any personally identifiable information. The only data collected and used for providing and improving the service is through Firebase Analytics and Crashlytics, as mentioned in this Privacy Policy.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>Firebase Analytics and Crashlytics (Opt-in)</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>Firebase Analytics and Crashlytics are third-party services provided by Google that help me analyze and understand how the Service is used and to identify and fix issues. These services may collect data and information, such as device information, usage statistics, and crash reports. This data is pseudonymous but may still be considered personal data under privacy regulations.</p>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>The use of Firebase Analytics and Crashlytics is optional and requires user consent. You can choose to opt-in to these services through the settings page in the app or in the onboarding screen. By opting in and using the Service, you agree to the processing of this data by Firebase Analytics and Crashlytics for the purposes described above. You can learn more about Firebase's privacy practices by visiting their <a href="https://firebase.google.com/support/privacy">Privacy Policy</a> and <a href="https://firebase.google.com/terms">Terms of Service</a>.</p>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>Please note that, since the data collected through Firebase Analytics and Crashlytics is pseudonymous, I cannot provide users with specific data about them upon request. However, users can learn more about the types of data collected and how it's used by referring to the Firebase privacy policy and terms of service.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>iCloud Backups</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>The Dominate Life app uses iCloud to store and manage backups of your account data. This feature helps you easily restore your app data in case you switch devices or need to recover information. The data stored in iCloud is encrypted and accessible only to you. I do not have access to the information stored in your iCloud account.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>Service Providers</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>I may employ third-party companies and individuals to facilitate the Service, provide the Service on my behalf, or perform Service-related services. These third parties have access to your Personal Data only to perform these tasks on my behalf and are obligated not to disclose or use it for any other purpose.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>Changes to This Privacy Policy</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>I may update this Privacy Policy from time to time. You are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

                      <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '16px' }}>Contact Us</h2>

                      <p style={{ textAlign: 'left', fontSize: '18px' }}>If you have any questions or suggestions about this Privacy Policy, do not hesitate to contact me at <a href="mailto:dominatelifeapp@gmail.com">dominatelifeapp@gmail.com</a>.</p>
                    </div>
                  </>
              </div>
            </div >
          </Route>
          <Route path="/">
            <div className="App">
              <div className="App-header First-Page">
              <>
                    <div className="Left-Container">
                      <div>
                        <span className="App-Title">DOMINATE LIFE</span>
                        <p className="App-Description">An iOS app, that lets you turn your life into a game. Its main goal is to help you remain to be motivated throughout life.</p>

                        <div className="talking-points">
                          <TalkingPoint
                            description='Your personality traits. How would you describe yourself? Empathetic, brave, funny? There is no limit! Maybe you want to improve your confidence? Or maybe you just want to track your progress on it. The motto is: "Whatever you want, you got it."'
                            title="Attributes"
                            icon={faSmile}
                          />
                          <TalkingPoint
                            description="Your hobbies, your profession, your passion. Again, you have the freedom to create whatever skill you have or want to learn. Want to get better at Drawing? Track it. Want to learn Guitar? Track it."
                            title="Skills"
                            icon={faGuitar}
                          />
                          <TalkingPoint
                            description="You can think of quests as tasks you do in your life, which could be as simple as brushing your teeth, or as complex as achieve happiness in life. Complete them to level up your character, skills and attributes."
                            title="Quests"
                            icon={faQuestion}
                          />
                          <TalkingPoint
                            description="This app is all about you. Like the points above, you also start at level 1, and will have to fight your way through life to level up, each level being harder than the previous one."
                            title="You"
                            icon={faStreetView}
                          />
                        </div>
                      </div>

                      <div className="bottomRow">
                        <Link className="privacy-policy" to="/privacy-policy">Privacy Policy</Link>
                        <div className="branding">
                          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dominatelifeapp/">
                            <FontAwesomeIcon className="instagram-icon" size="lg" icon={faInstagram} />
                          </a>
                          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCzYJPusHrbrigO4IrDj4bGA">
                            <FontAwesomeIcon className="youtube-icon" size="lg" icon={faYoutube} />
                          </a>

                          <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/dominate-life-lite/id1586184650">
                            <FontAwesomeIcon className="apple-icon" size="lg" icon={faApple} />
                          </a>
                          <a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/dominatelifeapp">
                            <FontAwesomeIcon className="reddit-icon" size="lg" icon={faRedditAlien} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <RightContainer />
                  </>
              </div>
            </div >
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
